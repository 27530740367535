import * as React from "react"
import { Link } from "gatsby"
import { useForm } from "@formspree/react";
import { useTranslation } from 'gatsby-plugin-react-i18next';

// images
import VericodeLogo from '../images/vericode-logo.svg';

// icons
import { IconFacebook, IconLinkedin, IconInstagram, IconArrowUp } from '../components/icons';
import CamposHiddenMarketing from "./campos-hidden-marketing";

const NewsletterForm = () => {
  const [state, handleSubmit] = useForm("xeqdoneg");
  const { t } = useTranslation();

  if (state.succeeded) {
    if (typeof window !== "undefined") {
      window.dataLayer.push({
        'event': 'sendEvent',
        'eventCategory': 'newsletter: ',
        'eventAction': 'submit',
        'eventLabel': 'newsletter',
      });
    }

    return (
      <div className="flex items-center justify-center py-6 mx-auto text-2xl">
        <span>{t('thanks')}</span>
      </div>
    );
  }

  return (
    <>
      <div className="grid items-center lg:grid-cols-2">
        <h3>{t('newsletter.headline')}</h3>

        <form target="_blank" action="" className="form newsletter__form" onSubmit={handleSubmit}>
          <div className="form__control form__control--group form__control--primary">
            <input name="email_newsletter" type="email" placeholder={t('newsletter.input.placeholder')}/>

            <button type="submit" className="button button--primary" disabled={state.submitting}>
              {state.submitting ? t('newsletter.button.loading') : t('newsletter.button.normal')}
            </button>
          </div>

          <CamposHiddenMarketing/>
        </form>
      </div>
    </>
  )
}

const Footer = () => {
  function onClickScrollTop() {
    window.scrollTo(0,0);
  }

  const { t } = useTranslation();

  return (
    <>
    <footer className="footer">
      <div className="container">
        <div className="grid lg:grid-cols-2">
          <button className="footer__logo" onClick={onClickScrollTop}>
            <img src={VericodeLogo} alt="Vericode logo" width="120" />
            <IconArrowUp className="icon" />
          </button>

          <nav className="footer__links">
            <Link to="/contato">{t('links.contact')}</Link>
            <a target="_blank" href="https://insights.vericode.com.br/newsletter-vericode" rel="noreferrer">{t('links.newsletter')}</a>
            <Link to="/politica-privacidade">{t('links.privacy')}</Link>
            <a rel="noreferrer" target="_blank" href="//br.linkedin.com/company/vericodetecnologia">
              <span className="hidden">Linkedin</span>
              <IconLinkedin className="icon icon--small"/>
            </a>
            <a rel="noreferrer" target="_blank" href="//www.facebook.com/Vericodeit/">
              <span className="hidden">Facebook</span>
              <IconFacebook className="icon icon--small"/>
            </a>
            <a rel="noreferrer" target="_blank" href="//instagram.com/vericode.br">
              <span className="hidden">Instagram</span>
              <IconInstagram className="icon icon--small"/>
            </a>
          </nav>
        </div>
      </div>
    </footer>
    </>
  )
}

export default Footer
