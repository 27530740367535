import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { Flag } from './icons';

const LanguageSwitcher = () => {
  const { languages, changeLanguage, language: selectedLang } = useI18next();

  function handleKeyDown(e, lang) {
    if (e.keyCode === 13) handleClick(e, lang);
  }

  function handleClick(e, lang) {
    e.preventDefault();
    changeLanguage(lang);
  }

  return (
    <div className='flex items-center space-x-2 h-full'>
      {languages.map((langInitials) => (
        <div
          key={langInitials}
          role={'button'}
          tabIndex={0}
          className={`border-2 rounded-full cursor-pointer bg-accent ${langInitials === selectedLang ? 'border-secondary' : 'border-primary'}`}
          onClick={(e) => handleClick(e, langInitials)}
          onKeyDown={(e) => handleKeyDown(e, langInitials)}
        >
          <Flag
            locale={langInitials}
            className="object-cover icon icon--small object-center rounded-full"
          />
        </div>
      ))}
    </div>
  )
}

export default LanguageSwitcher