import * as React from "react"

export const IconArrowRight = (props) => {
  return (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M16.01 11H4v2h12.01v3L20 12l-3.99-4z" /></svg>)
}

export const IconArrowUp = (props) => {
  return (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0z" fill="none" /><path d="M11 7.99L11 20L13 20L13 7.99L16 7.99L12 4L8 7.99L11 7.99Z" /></svg>)
}

export const IconFacebook = (props) => {
  return (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512" width="24"><path d="M449.446,0c34.525,0 62.554,28.03 62.554,62.554l0,386.892c0,34.524 -28.03,62.554 -62.554,62.554l-106.468,0l0,-192.915l66.6,0l12.672,-82.621l-79.272,0l0,-53.617c0,-22.603 11.073,-44.636 46.58,-44.636l36.042,0l0,-70.34c0,0 -32.71,-5.582 -63.982,-5.582c-65.288,0 -107.96,39.569 -107.96,111.204l0,62.971l-72.573,0l0,82.621l72.573,0l0,192.915l-191.104,0c-34.524,0 -62.554,-28.03 -62.554,-62.554l0,-386.892c0,-34.524 28.029,-62.554 62.554,-62.554l386.892,0Z" /></svg>)
}

export const IconLinkedin = (props) => {
  return (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512" width="24"><path d="M449.446,0c34.525,0 62.554,28.03 62.554,62.554l0,386.892c0,34.524 -28.03,62.554 -62.554,62.554l-386.892,0c-34.524,0 -62.554,-28.03 -62.554,-62.554l0,-386.892c0,-34.524 28.029,-62.554 62.554,-62.554l386.892,0Zm-288.985,423.278l0,-225.717l-75.04,0l0,225.717l75.04,0Zm270.539,0l0,-129.439c0,-69.333 -37.018,-101.586 -86.381,-101.586c-39.804,0 -57.634,21.891 -67.617,37.266l0,-31.958l-75.021,0c0.995,21.181 0,225.717 0,225.717l75.02,0l0,-126.056c0,-6.748 0.486,-13.492 2.474,-18.315c5.414,-13.475 17.767,-27.434 38.494,-27.434c27.135,0 38.007,20.707 38.007,51.037l0,120.768l75.024,0Zm-307.552,-334.556c-25.674,0 -42.448,16.879 -42.448,39.002c0,21.658 16.264,39.002 41.455,39.002l0.484,0c26.165,0 42.452,-17.344 42.452,-39.002c-0.485,-22.092 -16.241,-38.954 -41.943,-39.002Z" /></svg>)
}

export const IconInstagram = (props) => {
  return (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 420 419.997" width="24"><path d="M388.818,146.28a24.3,24.3,0,1,1-24.295-24.295A24.3,24.3,0,0,1,388.818,146.28ZM466,256l-0.005.306-1.38,88.105a121.58,121.58,0,0,1-120.2,120.2L256,466l-0.306-.006-88.105-1.376a121.586,121.586,0,0,1-120.206-120.2L46,256l0.006-.306,1.376-88.108a121.59,121.59,0,0,1,120.206-120.2L256,46l0.306,0.006,88.105,1.376a121.584,121.584,0,0,1,120.2,120.2Zm-39.112,0-1.374-87.8A82.654,82.654,0,0,0,343.8,86.485L256,85.114l-87.8,1.371A82.658,82.658,0,0,0,86.484,168.2L85.113,256l1.371,87.8A82.655,82.655,0,0,0,168.2,425.515l87.8,1.371,87.8-1.371A82.651,82.651,0,0,0,425.514,343.8Zm-63.048,0A107.841,107.841,0,1,1,256,148.159,107.962,107.962,0,0,1,363.84,256Zm-39.107,0A68.734,68.734,0,1,0,256,324.734,68.812,68.812,0,0,0,324.732,256Z" transform="translate(-46 -46.001)" /></svg>)
}

export const IconTwitter = (props) => {
  return (<svg className={props.className} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 512 512"><path d="M449.446,0c34.525,0 62.554,28.03 62.554,62.554l0,386.892c0,34.524 -28.03,62.554 -62.554,62.554l-386.892,0c-34.524,0 -62.554,-28.03 -62.554,-62.554l0,-386.892c0,-34.524 28.029,-62.554 62.554,-62.554l386.892,0Zm-253.927,424.544c135.939,0 210.268,-112.643 210.268,-210.268c0,-3.218 0,-6.437 -0.153,-9.502c14.406,-10.421 26.973,-23.448 36.935,-38.314c-13.18,5.824 -27.433,9.809 -42.452,11.648c15.326,-9.196 26.973,-23.602 32.49,-40.92c-14.252,8.429 -30.038,14.56 -46.896,17.931c-13.487,-14.406 -32.644,-23.295 -53.946,-23.295c-40.767,0 -73.87,33.104 -73.87,73.87c0,5.824 0.613,11.494 1.992,16.858c-61.456,-3.065 -115.862,-32.49 -152.337,-77.241c-6.284,10.881 -9.962,23.601 -9.962,37.088c0,25.594 13.027,48.276 32.95,61.456c-12.107,-0.307 -23.448,-3.678 -33.41,-9.196l0,0.92c0,35.862 25.441,65.594 59.311,72.49c-6.13,1.686 -12.72,2.606 -19.464,2.606c-4.751,0 -9.348,-0.46 -13.946,-1.38c9.349,29.426 36.628,50.728 68.965,51.341c-25.287,19.771 -57.164,31.571 -91.8,31.571c-5.977,0 -11.801,-0.306 -17.625,-1.073c32.337,21.15 71.264,33.41 112.95,33.41Z" /></svg>)
}

export const Flag = (props) => {
  const flag = {
    'en': <UsFlag className={props.className} />,
    'pt': <BrFlag className={props.className} />
  }
  return flag[props.locale]
}

const UsFlag = (props) => {
  return (
    <svg className={props.className} width="100%" height="100%" viewBox="14 2 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.105469 0H57.2107V35H0.105469V0Z" fill="white" />
      <path d="M0.105469 0H57.2107V4.60526H0.105469V0Z" fill="#D22F27" />
      <path d="M0.105469 8.28906H57.2107V11.9733H0.105469V8.28906Z" fill="#D22F27" />
      <path d="M0.105469 15.6582H57.2107V19.3424H0.105469V15.6582Z" fill="#D22F27" />
      <path d="M0.105469 0H29.5792V19.3421H0.105469V0Z" fill="#1E50A0" />
      <path d="M0.105469 23.0273H57.2107V26.7116H0.105469V23.0273Z" fill="#D22F27" />
      <path d="M4.24856 6.21782C5.13876 6.21782 5.8604 5.49618 5.8604 4.60598C5.8604 3.71579 5.13876 2.99414 4.24856 2.99414C3.35837 2.99414 2.63672 3.71579 2.63672 4.60598C2.63672 5.49618 3.35837 6.21782 4.24856 6.21782Z" fill="white" />
      <path d="M11.6157 6.21782C12.5059 6.21782 13.2276 5.49618 13.2276 4.60598C13.2276 3.71579 12.5059 2.99414 11.6157 2.99414C10.7256 2.99414 10.0039 3.71579 10.0039 4.60598C10.0039 5.49618 10.7256 6.21782 11.6157 6.21782Z" fill="white" />
      <path d="M18.9868 6.21782C19.877 6.21782 20.5987 5.49618 20.5987 4.60598C20.5987 3.71579 19.877 2.99414 18.9868 2.99414C18.0966 2.99414 17.375 3.71579 17.375 4.60598C17.375 5.49618 18.0966 6.21782 18.9868 6.21782Z" fill="white" />
      <path d="M26.354 6.21782C27.2442 6.21782 27.9659 5.49618 27.9659 4.60598C27.9659 3.71579 27.2442 2.99414 26.354 2.99414C25.4638 2.99414 24.7422 3.71579 24.7422 4.60598C24.7422 5.49618 25.4638 6.21782 26.354 6.21782Z" fill="white" />
      <path d="M22.6704 9.90142C23.5606 9.90142 24.2823 9.17977 24.2823 8.28958C24.2823 7.39938 23.5606 6.67773 22.6704 6.67773C21.7802 6.67773 21.0586 7.39938 21.0586 8.28958C21.0586 9.17977 21.7802 9.90142 22.6704 9.90142Z" fill="white" />
      <path d="M15.3013 9.90142C16.1915 9.90142 16.9131 9.17977 16.9131 8.28958C16.9131 7.39938 16.1915 6.67773 15.3013 6.67773C14.4111 6.67773 13.6895 7.39938 13.6895 8.28958C13.6895 9.17977 14.4111 9.90142 15.3013 9.90142Z" fill="white" />
      <path d="M7.93215 9.90142C8.82235 9.90142 9.544 9.17977 9.544 8.28958C9.544 7.39938 8.82235 6.67773 7.93215 6.67773C7.04196 6.67773 6.32031 7.39938 6.32031 8.28958C6.32031 9.17977 7.04196 9.90142 7.93215 9.90142Z" fill="white" />
      <path d="M4.24856 13.587C5.13876 13.587 5.8604 12.8653 5.8604 11.9751C5.8604 11.0849 5.13876 10.3633 4.24856 10.3633C3.35837 10.3633 2.63672 11.0849 2.63672 11.9751C2.63672 12.8653 3.35837 13.587 4.24856 13.587Z" fill="white" />
      <path d="M11.6157 13.587C12.5059 13.587 13.2276 12.8653 13.2276 11.9751C13.2276 11.0849 12.5059 10.3633 11.6157 10.3633C10.7256 10.3633 10.0039 11.0849 10.0039 11.9751C10.0039 12.8653 10.7256 13.587 11.6157 13.587Z" fill="white" />
      <path d="M18.9868 13.587C19.877 13.587 20.5987 12.8653 20.5987 11.9751C20.5987 11.0849 19.877 10.3633 18.9868 10.3633C18.0966 10.3633 17.375 11.0849 17.375 11.9751C17.375 12.8653 18.0966 13.587 18.9868 13.587Z" fill="white" />
      <path d="M26.354 13.587C27.2442 13.587 27.9659 12.8653 27.9659 11.9751C27.9659 11.0849 27.2442 10.3633 26.354 10.3633C25.4638 10.3633 24.7422 11.0849 24.7422 11.9751C24.7422 12.8653 25.4638 13.587 26.354 13.587Z" fill="white" />
      <path d="M22.6704 17.2706C23.5606 17.2706 24.2823 16.5489 24.2823 15.6587C24.2823 14.7685 23.5606 14.0469 22.6704 14.0469C21.7802 14.0469 21.0586 14.7685 21.0586 15.6587C21.0586 16.5489 21.7802 17.2706 22.6704 17.2706Z" fill="white" />
      <path d="M15.3013 17.2706C16.1915 17.2706 16.9131 16.5489 16.9131 15.6587C16.9131 14.7685 16.1915 14.0469 15.3013 14.0469C14.4111 14.0469 13.6895 14.7685 13.6895 15.6587C13.6895 16.5489 14.4111 17.2706 15.3013 17.2706Z" fill="white" />
      <path d="M7.93215 17.2706C8.82235 17.2706 9.544 16.5489 9.544 15.6587C9.544 14.7685 8.82235 14.0469 7.93215 14.0469C7.04196 14.0469 6.32031 14.7685 6.32031 15.6587C6.32031 16.5489 7.04196 17.2706 7.93215 17.2706Z" fill="white" />
      <path d="M0.105469 30.3955H57.2107V35.0008H0.105469V30.3955Z" fill="#D22F27" />
    </svg>
  )
}

const BrFlag = (props) => {
  return (
    <svg className={props.className} width="100%" height="100%" viewBox="14 2 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H57.1053V35H0V0Z" fill="#5C9E31" />
      <path d="M49.7584 17.5221L28.4296 30.9971L7.34766 17.7689L28.6765 4.29395L49.7584 17.5221Z" fill="#FCEA2B" />
      <path d="M28.5512 25.7899C33.1293 25.7899 36.8407 22.0786 36.8407 17.5004C36.8407 12.9223 33.1293 9.21094 28.5512 9.21094C23.973 9.21094 20.2617 12.9223 20.2617 17.5004C20.2617 22.0786 23.973 25.7899 28.5512 25.7899Z" fill="#1E50A0" />
      <path d="M36.0677 20.9838C36.3715 20.3213 36.5871 19.6219 36.709 18.9034C36.0241 17.4715 35.0255 16.2123 33.7873 15.2192C32.5491 14.2262 31.1031 13.5248 29.5567 13.1672C28.0102 12.8096 26.4031 12.8049 24.8547 13.1536C23.3062 13.5022 21.8561 14.1951 20.6122 15.1809C20.4336 15.7848 20.3245 16.4071 20.2871 17.0357C21.5094 16.033 22.9495 15.3303 24.492 14.9839C26.0345 14.6374 27.6367 14.6568 29.1704 15.0405C30.7041 15.4242 32.1267 16.1616 33.3244 17.1936C34.522 18.2256 35.4615 19.5236 36.0677 20.9838Z" fill="white" />
    </svg>
  )
}