import * as React from 'react';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from 'usehooks-ts';

const CookieConsent = () => {
  const location = useLocation();
  const { t } = useTranslation();

  if (typeof window !== 'undefined') {
    initializeAndTrack(location);
  }

  const [bannerHidden, setBannerHidden] = useLocalStorage(
    'consentCookieHidden',
    false,
  );

  const EnableAnalytics = () => {
    document.cookie = 'gatsby-gdpr-google-analytics=true';
    document.cookie = 'gatsby-gdpr-google-tagmanager=true';
    setBannerHidden(true);
  };

  return (
    <>
      {!bannerHidden && (
        <div className="fixed bottom-md right-md bg-white p-10 max-w-2xl rounded shadow-lg">
          <h5 className="text-lg font-bold mb-sm text-primary">
            {t('cookies.title')}
          </h5>
          <p className="text-sm">{t('cookies.message')}</p>
          <div className="flex space-x-xs mt-md">
              <button className="button" onClick={EnableAnalytics}>
                {t('cookies.button')}
              </button>
          </div>
      </div>
      )}
    </>
  );
};

export default CookieConsent;
