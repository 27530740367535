import * as React from "react"
import { Helmet } from "react-helmet"
import { useTranslation } from 'gatsby-plugin-react-i18next';

// typography
import "@fontsource/montserrat";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/700.css";

// components
import Header from './header';
import Footer from './footer';

// media
import favicon from "../images/icon.png";
import CookieConsent from "./cookie-consent";

const UrlSearchParamsToLocalStorage = () => {
  const searchParamsMap = []

  if (typeof window !== "undefined") {
    const searchParams = new URLSearchParams(window.location.search);

    for (let param of searchParams.entries()) {
      searchParamsMap.push({ key: param[0], value: param[1] })
    }
  }

  if (searchParamsMap.length) {
    sessionStorage.setItem('utm', JSON.stringify(searchParamsMap));
  }
}

const Layout = ({ location, seo, children, headerStyle = 'full' }) => {
  const { t } = useTranslation();

  React.useEffect(UrlSearchParamsToLocalStorage, [])
  return (
    <>
      <Helmet
        title={seo ? `${t(seo.title)} | Vericode` : `Vericode | Trusting Digital Technologies`}
        description={seo ? `${t(seo.description)}` : t('seo.description')}
        link={[
          { rel: "shortcut icon", type: "image/png", href: `${favicon}` },
        ]}
        bodyAttributes={{
          class: `header-style-${headerStyle}`
        }}
      >
        <meta name="google-site-verification" content="dl-7qQqQClDFiBxn9SQ3U5EMnYW2IFOhD6o5EmG-1lQ" />
      </Helmet>

      <Header headerStyle={headerStyle} location={location} />

      <main>
        {children}
      </main>

      <CookieConsent />
      <Footer />
      <script type="text/javascript" async src="https://d335luupugsy2.cloudfront.net/js/loader-scripts/1f42b8d9-e47b-4eab-9328-0df876d7838b-loader.js" />
    </>
  )
}

export default Layout