import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';

// images
import VericodeLogoInverted from '../images/vericode-logo-inverted.svg';

// icons
import { IconFacebook, IconLinkedin, IconInstagram, IconArrowRight } from '../components/icons';
import LanguageSwitcher from './language-switcher';
import { useCallback } from 'react';

const Header = ({ headerStyle }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isPageScrolled, setMenuOpaque] = useState(false);
  const { t } = useI18next();

  const handleScroll = useCallback(() => {
    const isScroll = window.scrollY > 70;
    if (isScroll !== isPageScrolled) {
      setMenuOpaque(isScroll)
    }
  }, [isPageScrolled])

  useEffect(() => {
    document.addEventListener('scroll', handleScroll)

    return () => {
      document.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll])

  return (
    <header className={`header ${isMenuOpen ? 'header--open' : ''} ${headerStyle === 'simple' && 'header--simple'} ${isPageScrolled ? 'header--scrolled' : ''}`}>
      <div className="container header__container">
        <Link className="header__logo" to="/">
          <img src={VericodeLogoInverted} alt="Vericode logo" width="110" />
        </Link>

        {headerStyle === 'simple' && <>
          <Link className="button button--nude ml-xs" to="/">
            <span><Trans i18nKey='nav.visit-button' /></span>
            <IconArrowRight className="icon" />
          </Link>
        </>
        }

        {headerStyle === 'full' && <>
          <button className="header__toggle" onClick={() => setMenuOpen(!isMenuOpen)}>
            <span></span>
            <span></span>
          </button>

          <nav className="header__navigation">
            <ul className="navigation navigation--main">
              <li className="navigation__item">
                <Link activeClassName="navigation__link--active" className="navigation__link" to="/sobre"><Trans i18nKey='nav.about' /></Link>
              </li>

              <li className="navigation__item navigation__item--hasSubmenu">
                <Link activeClassName="navigation__link--active" className="navigation__link" to="/servicos"><Trans i18nKey='nav.services.title' /></Link>
                <div className="navigation__submenu submenu">
                  <div className="container flex pt-lg">
                    <div className="w-2/6">
                      <h2 className="title title--display"><Trans i18nKey='nav.services.title' /></h2>
                      <Link to="/servicos" className="button button--nude">
                        <Trans i18nKey='nav.services.button' />
                        <IconArrowRight className="icon" />
                      </Link>
                    </div>

                    <div className="grid flex-1 grid-cols-3 gap-x-lg gap-y-sm pt-xs submenu__links">

                      <Link to="/servicos/devsecops">{t('nav.services.ad')}</Link>
                      <Link to="/servicos/analise-de-codigo-fonte">{t('nav.services.acf')}</Link>
                      <Link to="/servicos/testes-automatizados">{t('nav.services.ta')}</Link>
                      <Link to="/servicos/avaliacoes-nao-funcionais">{t('nav.services.tcs')}</Link>
                      <Link to="/servicos/testes-de-seguranca">{t('nav.services.tsv')}</Link>
                      <Link to="/servicos/virtualizacao-de-servicos">{t('nav.services.vm')}</Link>
                      <Link to="/servicos/capacidade">{t('nav.services.ccp')}</Link>
                      <Link to="/servicos/log-analytics">{t('nav.services.la')}</Link>
                      <Link to="/servicos/sre">{t('nav.services.sre')}</Link>
                    </div>
                  </div>
                </div>
              </li>

              <li className="navigation__item">
                <a className="navigation__link" rel="noreferrer" target="_blank" href="//dott.ai">{t('nav.products')}</a>
              </li>

              <li className="navigation__item">
                <a className="navigation__link" rel="noreferrer"  href="/#partners">{t('nav.partners')}</a>
              </li>

              <li className="navigation__item navigation__item--hasSubmenu">
                <a className="navigation__link" target="_blank" href="//insights.vericode.com.br/conteudos-ricos" rel="noreferrer">
                  {t('nav.insights.title')}
                </a>
                <div className="navigation__submenu submenu">
                  <div className="container flex pt-lg">
                    <div className="w-2/6">
                      <h2 className="title title--display">{t('nav.insights.title')}</h2>
                      <a target="_blank" href="//insights.vericode.com.br/conteudos-ricos" className="button button--nude" rel="noreferrer">
                        <Trans i18nKey='nav.insights.button' />
                        <IconArrowRight className="icon" />
                      </a>
                    </div>

                    <div className="grid flex-1 grid-cols-3 gap-x-lg gap-y-sm pt-xs submenu__links">
                      <a className="navigation__link" rel="noreferrer" target="_blank" href="//blog.vericode.com.br">{t('nav.insights.blog')}</a>
                      <a className="navigation__link" rel="noreferrer" target="_blank" href="//insights.vericode.com.br/conteudos-ricos">{t('nav.insights.resources')}</a>
                    </div>
                  </div>
                </div>
              </li>

              <li className="navigation__item">
                <a className="navigation__link" rel="noreferrer" target="_blank" href="https://vericode.gupy.io/">{t('nav.jobs')}</a>
              </li>

              <li className="navigation__item">
                <Link activeClassName="navigation__link--active" className="navigation__link" to="/contato">{t('nav.contact')}</Link>
              </li>

              <li className="navigation__item">
                <LanguageSwitcher />
              </li>
            </ul>

            <ul className="navigation navigation--social">
              <a rel="noreferrer" target="_blank" href="//br.linkedin.com/company/vericodetecnologia">
                <span className="hidden">Linkedin</span>
                <IconLinkedin className="icon icon--small" />
              </a>
              <a rel="noreferrer" target="_blank" href="//www.facebook.com/Vericodeit/">
                <span className="hidden">Facebook</span>
                <IconFacebook className="icon icon--small" />
              </a>
              <a rel="noreferrer" target="_blank" href="//instagram.com/vericode.br">
                <span className="hidden">Instagram</span>
                <IconInstagram className="icon icon--small" />
              </a>
            </ul>
          </nav>
        </>}
      </div>
    </header>
  )
}

export default Header