import * as React from "react"

const getQueryParams = () => {
  const searchParamsMap = []

  if (typeof window !== "undefined") {
    const searchParams = new URLSearchParams(window.location.search);
  
    for(let param of searchParams.entries()) {
      searchParamsMap.push({ key: param[0], value: param[1] })
    }
  }

  return searchParamsMap;
}

const CamposHiddenMarketing = () => {
  let utmTags = []

  if (typeof window !== "undefined") {
    utmTags = JSON.parse(sessionStorage.getItem('utm')) || getQueryParams()
  }

  return (
    <>
      {utmTags.map(param => {
        return (<input key={param.key} type="hidden" name={param.key} value={param.value} />)
      })}
    </>
  )
}

export default CamposHiddenMarketing